import Script from 'next/script';
import type { AppProps } from 'next/app';
import React, { useEffect, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import config from '../config';
import { Header } from '../components/Navigation';
import { Footer } from '../components/Layout';
import { Preloader } from '../components/Preloader';

// swiper styles
import 'swiper/scss';

// bootstrap grid styles
import '../node_modules/bootstrap/scss/bootstrap-grid.scss';

// mapbox
import 'mapbox-gl/dist/mapbox-gl.css';

// styles
import '../styles/_main.scss';

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const [pageTheme, setPageTheme] = useState({
    color: config.pageColorHome.color,
    mode: config.pageColorHome.mode,
    invertColors: config.pageColorHome.invertColors,
  });
  const [activeNavigation, setActiveNavigation] = useState<boolean>(false);
  const [preloaderVisible, setPreloaderVisible] = useState<boolean>(true);
  const isMounted = React.useRef(false);

  const mainWrapperClass = classNames('main-wrapper', {
    ['main-wrapper--invert-colors']: pageTheme.invertColors,
  });

  useEffect(() => {
    // close subnavigation / navigation on page transition
    setActiveNavigation(false);

    // change pageColor on page transition
    switch (router.pathname) {
      case '/':
        setPageTheme({
          color: config.pageColorHome.color,
          mode: config.pageColorHome.mode,
          invertColors: config.pageColorHome.invertColors,
        });
        return;
      case '/residences':
        setPageTheme({
          color: config.pageColorResidences.color,
          mode: config.pageColorResidences.mode,
          invertColors: config.pageColorResidences.invertColors,
        });
        return;
      case '/press':
        setPageTheme({
          color: config.pageColorPress.color,
          mode: config.pageColorPress.mode,
          invertColors: config.pageColorPress.invertColors,
        });
        return;
      case '/neighborhood':
        setPageTheme({
          color: config.pageColorNeighborhood.color,
          mode: config.pageColorNeighborhood.mode,
          invertColors: config.pageColorNeighborhood.invertColors,
        });
        return;
      case '/about-the-art':
        setPageTheme({
          color: config.pageColorAboutTheArt.color,
          mode: config.pageColorAboutTheArt.mode,
          invertColors: config.pageColorAboutTheArt.invertColors,
        });
        return;
      case '/views':
        setPageTheme({
          color: config.pageColorViews.color,
          mode: config.pageColorViews.mode,
          invertColors: config.pageColorViews.invertColors,
        });
        return;
      case '/amenities':
        setPageTheme({
          color: config.pageColorAmenities.color,
          mode: config.pageColorAmenities.mode,
          invertColors: config.pageColorAmenities.invertColors,
        });
        return;
      case '/penthouses':
        setPageTheme({
          color: config.pageColorPenthouses.color,
          mode: config.pageColorPenthouses.mode,
          invertColors: config.pageColorPenthouses.invertColors,
        });
        return;
      case '/contact':
        setPageTheme({
          color: config.pageColorContact.color,
          mode: config.pageColorContact.mode,
          invertColors: config.pageColorContact.invertColors,
        });
        return;
      case '/flex-living':
        setPageTheme({
          color: config.pageColorFlexLiving.color,
          mode: config.pageColorFlexLiving.mode,
          invertColors: config.pageColorFlexLiving.invertColors,
        });
        return;
      case '/legal':
      case '/credits':
      case '/accessibility':
      case '/team':
        setPageTheme({
          color: config.pageColorLegal.color,
          mode: config.pageColorLegal.mode,
          invertColors: config.pageColorLegal.invertColors,
        });
        return;
      case '/availability':
        setPageTheme({
          color: config.pageColorAvailability.color,
          mode: config.pageColorAvailability.mode,
          invertColors: config.pageColorAvailability.invertColors,
        });
        return;
      case '/legal':
        setPageTheme({
          color: config.pageColorPress.color,
          mode: config.pageColorPress.mode,
          invertColors: config.pageColorPress.invertColors,
        });
        return;
    }

    isMounted.current = true;

    // cleanup function
    return () => {
      isMounted.current = false;
    };
  }, [router]);

  useEffect(() => {
    const handleScroll = () => {
      // close subnavigation / navigation on scroll, only for desktop
      // navigation should not close when scroll on mobile
      // close subnavigation / navigation on scroll
      setActiveNavigation(false);
    };
    window.addEventListener('scroll', handleScroll);

    setTimeout(() => setPreloaderVisible(false), 3000);
  });

  return (
    <>
      {/* <PrismicProvider> */}
      {/* <PrismicPreview repositoryName={repositoryName}> */}
      <div
        className={mainWrapperClass}
        style={{
          backgroundColor: pageTheme.color,
          transition: 'background-color ease-in-out 0.5s',
        }}
      >
        {/* accessiBe */}
        <Script
          id="accessiBe"
          async
          strategy="lazyOnload"
          dangerouslySetInnerHTML={{
            __html: `
          (function(){
            var s    = document.createElement('script');
            var h    = document.querySelector('head') || document.body;
            s.src    = 'https://acsbapp.com/apps/app/dist/js/app.js';
            s.async  = true;
            s.onload = function(){
                acsbJS.init({
                    statementLink    : '',
                    footerHtml       : '',
                    hideMobile       : false,
                    hideTrigger      : false,
                    disableBgProcess : false,
                    language         : 'en',
                    position         : 'left',
                    leadColor        : '#000000',
                    triggerColor     : '#000000',
                    triggerRadius    : '50%',
                    triggerPositionX : 'left',
                    triggerPositionY : 'bottom',
                    triggerIcon      : 'people',
                    triggerSize      : 'medium',
                    triggerOffsetX   : 30,
                    triggerOffsetY   : 30,
                    mobile           : {
                        triggerSize      : 'small',
                        triggerPositionX : 'left',
                        triggerPositionY : 'bottom',
                        triggerOffsetX   : 20,
                        triggerOffsetY   : 20,
                        triggerRadius    : '50%'
                    }
                });
            };
            h.appendChild(s);
          })();
          `,
          }}
        />

        <CookieConsent disableStyles={true} buttonText="ok">
          Cookies Policy: We and our partners use cookies to better understand
          your needs and improve performance. To continue browsing the website
          and allow us to provide a better and more tailored experience, please
          click “OK”.
        </CookieConsent>

        <Preloader
          visible={preloaderVisible}
          pageColor={pageTheme.color}
          invertColors={pageTheme.invertColors}
        />

        <Header
          pageColor={pageTheme.color}
          setShowNavigation={setActiveNavigation}
          showNavigation={activeNavigation}
          theme={pageTheme.mode}
          activeNavItem={router.pathname}
          invertColors={pageTheme.invertColors}
        />

        <Script
          id="google-tag-manager"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-TKT23MP');`,
          }}
        />

        <Component {...pageProps} key={router.pathname} />

        <Footer pageTheme={pageTheme} />
      </div>
      {/* </PrismicPreview> */}
      {/* </PrismicProvider> */}
    </>
  );
}

export default MyApp;
