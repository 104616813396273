import Image from 'next/image';
import classNames from 'classnames';

interface Props {
  src: string;
  alt: string;
  alignment?: string;
  position?: string;
  hasBorderRadius?: boolean;
  offset?: string;
  objectFit?: string;
  width?: number;
  height?: number;
  // should be set to true
  // if image will be shown on pageload (above fold)
  priority?: boolean;
  id?: string;
  mobileOffset?: boolean;
}

export const ImageComponent: React.FunctionComponent<Props> = ({
  src,
  alt,
  alignment,
  hasBorderRadius = true,
  priority = false,
  mobileOffset = false,
  offset,
  objectFit,
  width,
  height,
  id,
  position,
}) => {
  const imageClass = classNames('image-container', {
    ['image-container--vertical']: alignment === 'vertical',
    ['image-container--horizontal']: alignment === 'horizontal',
    ['image-container--square']: alignment === 'square',
    ['image-container--border-radius']: hasBorderRadius,
    ['image-container--offset-mobile']: mobileOffset,
    ['image-container--offset-full']: offset === 'full',
    ['image-container--offset-right']: offset === 'right',
    ['image-container--offset-right--full']: offset === 'right-full',
    ['image-container--offset-left']: offset === 'left',
    ['image-container--offset-left--full']: offset === 'left-full',
    ['image-container--contain']: objectFit === 'contain',
    ['image-container--content-left']: position === 'left',
    ['image-container--content-right']: position === 'right',
  });

  return (
    <div className={imageClass}>
      {width && height ? (
        <Image
          src={src}
          alt={alt}
          blurDataURL={src}
          placeholder="blur"
          width={width}
          height={height}
          id={id}
        />
      ) : (
        <Image
          src={src}
          alt={alt}
          blurDataURL={src}
          placeholder="blur"
          layout="fill"
        />
      )}
    </div>
  );
};
