import classNames from 'classnames';
import { Section } from '../Sections';
import { Container, Row, Col } from 'reactstrap';
import { ImageComponent } from '../Media';
import { Icon } from '../Icon';
import { RichText, RichTextBlock } from 'prismic-reactjs';
import { Key } from 'react';

interface Props {
  content: any;
  section: Array<Section>;
}

interface Section {
  image_text__image_1?: Array<Image>;
  image_text__image_2?: Array<Image>;
  image_text__icon?: any;
  image_text__title?: any;
  image_text__text?: any;
}

interface Image {
  url?: string;
  alt?: string;
  dimensions?: {
    width?: number;
    height?: number;
  };
}

export const ImageTextSection: React.FunctionComponent<Props> = ({
  content,
}) => {
  return (
    <>
      {
        // @ts-ignore
        content &&
          content.map(
            (
              section: {
                image_text__image_1: { url: any; alt: any };
                image_text__image_2: { url: any; alt: any };
                image_text__icon: string;
                image_text__title: RichTextBlock[];
                image_text__text: RichTextBlock[];
              },
              i: number
            ) => (
              <Section hasBottom hasTop={i === 0 ? true : false} key={i}>
                <Container fluid>
                  <Row className={classNames({ ['reverse-content']: i % 2 })}>
                    <Col md={{ size: i === 1 ? 8 : 6 }}>
                      {section.image_text__image_1.url && (
                        <ImageComponent
                          src={section.image_text__image_1.url || ''}
                          alt={section.image_text__image_1.alt || ''}
                          alignment="horizontal"
                          offset={i % 2 ? 'right' : 'left'}
                        />
                      )}
                      {section.image_text__image_2.url && (
                        <div className="m-t-image">
                          <ImageComponent
                            src={section.image_text__image_2.url || ''}
                            alt={section.image_text__image_2.alt || ''}
                            alignment="horizontal"
                            offset={i % 2 ? 'right' : 'left'}
                          />
                        </div>
                      )}
                    </Col>
                    <Col md={{ size: i === 1 ? 4 : 6 }} lg={4}>
                      {section.image_text__icon &&
                        section.image_text__icon !== '_blank' && (
                          <Icon type={section.image_text__icon} />
                        )}
                      {RichText.asText(section.image_text__title) !== '' && (
                        <h3 className={'font--lg font--bold'}>
                          {RichText.asText(section.image_text__title)}
                        </h3>
                      )}
                      {RichText.asText(section.image_text__text) !== '' && (
                        <p>{RichText.asText(section.image_text__text)}</p>
                      )}
                    </Col>
                  </Row>
                </Container>
              </Section>
            )
          )
      }
    </>
  );
};
