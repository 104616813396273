import classNames from 'classnames';
import Link from 'next/link';

interface Props {
  title: string;
  outline?: string;
  filled?: string;
  href?: string;
  type?: string;
  onClick?: () => void;
  targetExternal?: boolean;
}

export const Button: React.FunctionComponent<Props> = ({
  title,
  href,
  outline,
  filled,
  type = 'button',
  onClick = null,
  targetExternal = false,
}) => {
  const target = targetExternal ? '_blank' : '_self';
  const buttonClass = classNames('button', {
    ['button--filled-dark']: filled === 'dark',
    ['button--filled-transparent']: filled === 'transparent',
    ['button--filled-white']: filled === 'white',
    ['button--dropdown']: type === 'dropdown',
    ['button--outline-dark']: outline === 'dark',
    ['button--outline-light']: outline === 'light',
  });

  // if onClick event is set,
  // div instead of Link will be rendered
  if (onClick !== null) {
    return (
      <div className={buttonClass} onClick={() => onClick()}>
        {title}
      </div>
    );
  }

  return (
    <Link href={href || '#'}>
      <a
        className={buttonClass}
        target={target}
        rel={targetExternal ? 'noreferrer' : ''}
      >
        {title}
      </a>
    </Link>
  );
};
