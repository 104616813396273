import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
interface Props {
  invertColors?: boolean;
  isNavOpen?: boolean;
}

export const MusicPlayer: React.FunctionComponent<Props> = ({
  invertColors = false,
  isNavOpen = false,
}) => {
  const [audio, setAudio] = useState<any>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const musicPlayerClass = classNames('music-player', {
    ['music-player--inverted']: invertColors,
    ['music-player--nav-open']: isNavOpen,
  });
  const barContainerClass = classNames('music-player__bar-container', {
    ['music-player__bar-container--sound-is-playing']: isPlaying,
  });

  // play & stop music
  function handleMusicPlayer() {
    if (audio !== null && !isPlaying) {
      audio.play();
      setIsPlaying(true);
    } else {
      audio.pause();
      setIsPlaying(false);
    }
  }

  // set audio on initial loading
  useEffect(() => {
    setAudio(new Audio('/menagerie.mp3'));
  }, []);

  return (
    <div className={musicPlayerClass} onClick={() => handleMusicPlayer()}>
      <div className={barContainerClass}>
        <motion.div
          className="music-player__bar"
          initial={{
            height: '20%',
          }}
          animate={{
            height: '60%',
            transition: {
              delay: Math.random(),
              duration: 0.7,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        />
        <motion.div
          className="music-player__bar"
          initial={{
            height: '17%',
          }}
          animate={{
            height: '51%',
            transition: {
              duration: 0.72,
              delay: Math.random(),
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        />
        <motion.div
          className="music-player__bar"
          initial={{
            height: '19%',
          }}
          animate={{
            height: '65%',
            transition: {
              delay: Math.random(),
              duration: 0.72,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        />
        <motion.div
          className="music-player__bar"
          initial={{
            height: '18%',
          }}
          animate={{
            height: '48%',
            transition: {
              delay: Math.random(),
              duration: 0.73,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        />
        <motion.div
          className="music-player__bar"
          initial={{
            height: '16%',
          }}
          animate={{
            height: '62%',
            transition: {
              delay: Math.random(),
              duration: 0.7,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        />
        <motion.div
          className="music-player__bar"
          initial={{
            height: '17%',
          }}
          animate={{
            height: '55%',
            transition: {
              delay: Math.random(),
              duration: 0.72,
              repeat: Infinity,
              repeatType: 'reverse',
            },
          }}
        />
      </div>
    </div>
  );
};
