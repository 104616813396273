import React, { useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { Logo } from '../Navigation'

interface Props {
  visible: boolean,
  pageColor: string,
  invertColors?: boolean
}

export const Preloader: React.FunctionComponent<Props> = ({
  visible,
  pageColor,
  invertColors
}) => {

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 2000)
  }, [])

  return (
    <>
      { visible && (
        <motion.div
          className='preloader'
          style={{ backgroundColor: pageColor }}
          animate={{ opacity: 0 }}
          transition={{
            delay: 2.5,
            duration: 0.5,
            ease: "linear"
          }}
        >
          <Logo
            pageColor='#000'
            invertColors={ invertColors }
            loading={ loading }
          />
        </motion.div>
      )}
    </>
  )
}
