import classNames from 'classnames';
import Image from 'next/image';

interface Props {
  src: string;
  alt: string;
  type?: string;
  logoOverlay?: any;
  // should be set to true
  // if image will be shown on pageload (above fold)
  priority?: boolean;
}

export const HeroImage: React.FunctionComponent<Props> = ({
  src,
  alt,
  type,
  logoOverlay,
  priority = false,
}) => {
  const heroImageClass = classNames('image-container--hero', {
    ['image-container--hero--frontpage']: type === 'frontpage',
  });

  return (
    <div className={heroImageClass}>
      {logoOverlay && logoOverlay?.url && (
        <div className="image-container__hero-overlaying-logo">
          <Image
            src={logoOverlay.url}
            alt={logoOverlay.alt || ''}
            blurDataURL={logoOverlay.url}
            width={logoOverlay?.dimensions?.width}
            height={logoOverlay?.dimensions?.height}
            placeholder="blur"
            layout="responsive"
          />
        </div>
      )}
      <Image
        src={src}
        alt={alt}
        blurDataURL={src}
        placeholder="blur"
        layout="fill"
        // priority={ priority }
      />
    </div>
  );
};
