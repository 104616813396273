import React, { useState } from 'react';
import Link from 'next/link';
import classNames from 'classnames';

export const SocialMediaIcons = () => {
  const [showIcons, setShowIcons] = useState(false);
  const socialIcons = classNames('social-media__icons', {
    ['social-media__icons--show']: showIcons,
  });

  return (
    <div className="social-media">
      <div
        className="social-media__button"
        onClick={() => {
          setShowIcons(!showIcons);
        }}
      >
        <svg
          // xmlns='http://www.w3.org/2000/svg'
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="#fff"
        >
          <path d="M5 7c2.761 0 5 2.239 5 5s-2.239 5-5 5-5-2.239-5-5 2.239-5 5-5zm11.122 12.065c-.073.301-.122.611-.122.935 0 2.209 1.791 4 4 4s4-1.791 4-4-1.791-4-4-4c-1.165 0-2.204.506-2.935 1.301l-5.488-2.927c-.23.636-.549 1.229-.943 1.764l5.488 2.927zm7.878-15.065c0-2.209-1.791-4-4-4s-4 1.791-4 4c0 .324.049.634.122.935l-5.488 2.927c.395.535.713 1.127.943 1.764l5.488-2.927c.731.795 1.77 1.301 2.935 1.301 2.209 0 4-1.791 4-4z" />
        </svg>
      </div>
      <ul className={socialIcons}>
        <li>
          <Link href="https://www.facebook.com/astoriawestNYC/">
            <a target="_blank" rel="noopener">
              {/* Facebook */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.03 33.03">
                <defs>
                  <clipPath id="a">
                    <path style={{ fill: 'none' }} d="M0 0h33.03v33.03H0z" />
                  </clipPath>
                </defs>
                <g data-name="Layer 2">
                  <g style={{ clipPath: 'url(#a)' }} data-name="Layer 1">
                    <circle
                      cx="16.52"
                      cy="16.52"
                      r="16.02"
                      style={{
                        stroke: '#231f20',
                        strokeMiterlimit: 10,
                        fill: 'none',
                      }}
                    />
                    <path d="M19.6 7.46a2.26 2.26 0 0 1 .58 0c.11 0 .19.2.19.44v2.54c.09.26-.13.47-.48.47h-1.8a.74.74 0 0 0-.8.43 1.13 1.13 0 0 0-.14.51v1.69a.78.78 0 0 0 0 .34h2.59c.27-.09.49.13.49.49v2.76c.08.25-.13.46-.47.46h-2.58V25c.12.3-.12.54-.54.54h-2.83c-.27.09-.49-.12-.49-.49v-7.33h-2.26c-.26.08-.46-.12-.46-.46v-2.79c-.07-.25.13-.45.46-.46h2.22v-2.36A4 4 0 0 1 14.4 8.8a4 4 0 0 1 2.19-1.23l.62-.11Z" />
                  </g>
                </g>
              </svg>
            </a>
          </Link>
        </li>
        <li>
          <Link href="https://www.instagram.com/astoriawestnyc/">
            <a target="_blank" rel="noopener">
              {/* Instagram */}
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.03 33.03">
                <defs>
                  <clipPath id="clip-path">
                    <path style={{ fill: 'none' }} d="M0 0h33.03v33.03H0z" />
                  </clipPath>
                </defs>
                <g data-name="Layer 2">
                  <g
                    style={{ clipPath: 'url(#clip-path)' }}
                    data-name="Layer 1"
                  >
                    <circle
                      cx="16.52"
                      cy="16.52"
                      r="16.02"
                      style={{
                        stroke: '#231f20',
                        strokeMiterlimit: 10,
                        fill: 'none',
                      }}
                    />
                    <path d="M16.59 19.46a3 3 0 1 1 3-3 3 3 0 0 1-3 3m0-7.59a4.63 4.63 0 1 0 4.63 4.62 4.62 4.62 0 0 0-4.63-4.62" />
                    <path d="M12.77 8.87a3.88 3.88 0 0 0-3.88 3.88v7.49a3.88 3.88 0 0 0 3.88 3.88h7.5a3.88 3.88 0 0 0 3.87-3.88v-7.49a3.88 3.88 0 0 0-3.87-3.88Zm7.5 17h-7.5a5.65 5.65 0 0 1-5.64-5.64v-7.48a5.65 5.65 0 0 1 5.64-5.65h7.5a5.65 5.65 0 0 1 5.64 5.65v7.49a5.65 5.65 0 0 1-5.64 5.64" />
                  </g>
                </g>
              </svg>
            </a>
          </Link>
        </li>
        {/* <li>
          <Link href='tel:9295791440'>
            <a>
              {/* Phone */}
        {/* <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33.03 33.03'><defs><clipPath id='a'>
                <path style={{ fill:'none' }} d='M0 0h33.03v33.03H0z'/></clipPath></defs><g data-name='Layer 2'>
                  <g style={{ clipPath:'url(#a)' }} data-name='Layer 1'><circle cx='16.52' cy='16.52' r='16.02' style={{ stroke:'#231f20', strokeMiterlimit:10, fill:'none' }}/>
                    <path d='M6.63 11.38a6 6 0 0 1 1.66-3.93 1.6 1.6 0 0 1 .56-.4 5.67 5.67 0 0 1 1.87-.43.64.64 0 0 1 .66.32c.23.39.44.79.67 1.18.51.86 1 1.72 1.52 2.57.36.6.35.87-.14 1.37-.28.28-.59.53-.88.79s-.69.6-1 .91a.64.64 0 0 0-.15.85c.38.68.76 1.37 1.19 2a13.46 13.46 0 0 0 4.83 4.44c.35.2.69.4 1.05.57a.6.6 0 0 0 .75-.12c.32-.34.63-.69.94-1s.55-.66.85-.95a.86.86 0 0 1 1.29-.13l2.92 1.71.87.5a.63.63 0 0 1 .33.64 6 6 0 0 1-.46 1.95 1.55 1.55 0 0 1-.36.49 5.91 5.91 0 0 1-4.1 1.67 6.27 6.27 0 0 1-2-.42c-.66-.23-1.32-.48-2-.73a13.09 13.09 0 0 1-3.48-2A27.21 27.21 0 0 1 9.81 19a17.68 17.68 0 0 1-2.87-5.82 5.51 5.51 0 0 1-.31-1.81' />
                  </g>
                </g>
              </svg> */}
        {/* </a>
          </Link> */}
        {/* </li> */}
        <li>
          <Link href="mailto:astoriawest@corcoran.com?subject=ASTORIA%20WEST%20NYC">
            <a>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33.03 33.03">
                <defs>
                  <clipPath id="clip-path">
                    <path style={{ fill: 'none' }} d="M0 0h33.03v33.03H0z" />
                  </clipPath>
                </defs>
                <g data-name="Layer 2">
                  <g
                    style={{ clipPath: 'url(#clip-path)' }}
                    data-name="Layer 1"
                  >
                    <circle
                      cx="16.52"
                      cy="16.52"
                      r="16.02"
                      style={{
                        stroke: '#231f20',
                        strokeMiterlimit: 10,
                        fill: 'none',
                      }}
                    />
                    <path d="M10.62 10.37a3.34 3.34 0 0 0-.7.08 2.91 2.91 0 0 0-1.13.55l7.73 7.18L24.25 11a3.15 3.15 0 0 0-1.83-.6" />
                    <path d="m8.8 22.05.12.08a3.21 3.21 0 0 0 1.71.49h11.68a4.22 4.22 0 0 0 .86-.11 2.56 2.56 0 0 0 1-.49c-1.73-1.62-3.45-3.22-5.18-4.82l-2.47 2.4L14 17.24l-5.2 4.81m16.2-.74.11-.16a3.36 3.36 0 0 0 .51-1.82v-5.92a3 3 0 0 0-.6-1.69l-5.16 4.79 5.14 4.8m-11.8-4.8L8 11.71a3.28 3.28 0 0 0-.62 1.93v5.71a4.11 4.11 0 0 0 .1.85A2.83 2.83 0 0 0 8 21.31c1.73-1.6 3.44-3.19 5.16-4.8" />
                    <path d="M22.42 10.37a3.15 3.15 0 0 1 1.83.6l-7.73 7.18L8.79 11a2.91 2.91 0 0 1 1.13-.52 3.34 3.34 0 0 1 .7-.08" />
                    <path d="m8.8 22.05 5.2-4.81 2.54 2.36 2.54-2.36c1.73 1.6 3.45 3.2 5.18 4.82a2.56 2.56 0 0 1-1 .49 4.22 4.22 0 0 1-.86.11H10.72A3.21 3.21 0 0 1 9 22.17l-.12-.08M25 21.31l-5.16-4.8L25 11.72a3 3 0 0 1 .6 1.69v5.92a3.36 3.36 0 0 1-.51 1.82l-.11.16m-11.78-4.8c-1.72 1.61-3.43 3.2-5.16 4.8a2.83 2.83 0 0 1-.52-1.11 4.11 4.11 0 0 1-.1-.85v-5.71A3.28 3.28 0 0 1 8 11.71l5.16 4.8" />
                  </g>
                </g>
              </svg>
            </a>
          </Link>
        </li>
      </ul>
    </div>
  );
};
