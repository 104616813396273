import React from 'react';
import classNames from 'classnames';

interface Props {
  invertColors?: boolean,
  isNavOpen?: boolean
}

export const AccessibilityButton: React.FunctionComponent<Props> = ({
  invertColors = false,
  isNavOpen = false,
}) => {
  const accessibilityButtonClass = classNames('accessibility-button', {
    ['accessibility-button--inverted']: invertColors,
    ['accessibility-button--nav-open']: isNavOpen
  })

  return (
    <div className={ accessibilityButtonClass } />
  )
}
