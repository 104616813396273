import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC } from 'react';

interface Props {
  children: React.ReactNode;
  isLegalTemplate?: boolean;
}

const variants = {
  hidden: { opacity: 0 },
  enter: { opacity: 1 },
  exit: { opacity: 0 },
};

export const Layout: FC<Props> = ({ children, isLegalTemplate = false }) => {
  const mainClass = classNames('main', {
    ['main--legal']: isLegalTemplate,
  });

  return (
    <motion.div
      layout="position" // fix stretching on loading the frontpage: https://www.framer.com/docs/animation/###the-content-stretches-undesirably
      className={mainClass}
      initial="hidden"
      animate="enter"
      exit="exit"
      variants={variants}
      transition={{
        type: 'linear',
        default: { duration: 0.5 },
      }}
    >
      {children}
    </motion.div>
  );
};
