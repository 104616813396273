import variables from './styles/variables.module.scss';

/* eslint-disable import/no-anonymous-default-export */
export default {
  pageColorHome: {
    color: variables.colorPinkPrimary,
    mode: 'light',
    invertColors: false,
  },
  pageColorPress: {
    color: variables.colorWhite,
    mode: 'dark',
    invertColors: false,
  },
  pageColorResidences: {
    color: variables.colorOrange,
    mode: 'light',
    invertColors: false,
  },
  pageColorNeighborhood: {
    color: variables.colorPinkPrimaryLight,
    mode: 'light',
    invertColors: false,
  },
  pageColorAmenities: {
    color: variables.colorOrangeLight,
    mode: 'light',
    invertColors: false,
  },
  pageColorPenthouses: {
    color: variables.colorOrangeLight,
    mode: 'light',
    invertColors: false,
  },
  pageColorContact: {
    color: variables.colorBlueDark,
    mode: 'light',
    invertColors: false,
  },
  pageColorAvailability: {
    color: variables.colorViolet,
    mode: 'light',
    invertColors: false,
  },
  pageColorViews: {
    color: variables.colorYellow,
    mode: 'light',
    invertColors: false,
  },
  pageColorAboutTheArt: {
    color: variables.colorBluePrimary,
    mode: 'light',
    invertColors: false,
  },
  pageColorFlexLiving: {
    color: variables.colorGreenLight,
    mode: 'light',
    invertColors: false,
  },
  pageColorCollaborators: {
    color: variables.colorBlueDark,
    mode: 'light',
    invertColors: false,
  },
  pageColorLegal: {
    color: variables.colorBlack,
    mode: 'light',
    invertColors: true,
  },
};
