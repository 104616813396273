import classNames from 'classnames';

interface Props {
  children: React.ReactNode;
  hasTop?: boolean;
  hasBottom?: boolean;
  hasBottomMobile?: boolean;
  fullHeight?: boolean;
  topSize?: string;
  bottomSize?: string;
  backgroundColor?: string;
  hasFooter?: boolean;
  // Emphasize a section to add a z-index of 100 in case the section below
  // would overlay content that is positioned absolutely.
  emphasize?: boolean;
}

export const Section: React.FunctionComponent<Props> = ({
  children,
  hasTop = false,
  hasBottom = true,
  hasBottomMobile = true,
  fullHeight = false,
  topSize,
  bottomSize,
  backgroundColor,
  hasFooter = false,
  // Emphasize a section to add a z-index of 100 in case the section below
  // would overlay content that is positioned absolutely.
  emphasize = false,
}) => {
  const sectionClass = classNames('section', {
    ['section--footer']: hasFooter,
    ['section--t']: hasTop,
    ['section--nb']: !hasBottom,
    ['section--nb--mobile']: !hasBottomMobile,
    ['section--full-height']: fullHeight,
    ['section--t--md']: hasTop && topSize === 'md',
    ['section--t--lg']: hasTop && topSize === 'lg',
    ['section--b--md']: hasBottom && bottomSize === 'md',
    ['section--b--lg']: hasBottom && bottomSize === 'lg',
    ['emphasize']: emphasize,
  });

  return (
    <section
      className={sectionClass}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {children}
    </section>
  );
};
