import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { MusicPlayer } from '../Player';
import { AccessibilityButton } from '../Accessibility';
import { Logo, MainNavigation, SubNavigation } from '.';

interface Props {
  pageColor?: string;
  setShowNavigation: Function;
  showNavigation?: boolean;
  theme?: string;
  activeNavItem?: string;
  invertColors?: boolean;
}

export const Header: FC<Props> = ({
  pageColor,
  setShowNavigation,
  showNavigation,
  theme,
  activeNavItem,
  invertColors = false,
}) => {
  const router = useRouter();
  const [loading, setLoading] = useState<boolean>(false);

  const burgerClass = classNames('header__burger', {
    ['header__burger--active']: showNavigation,
  });

  const headerClass = classNames('header', {
    ['header--dark']: theme === 'dark',
    ['header--inverted']: invertColors,
  });

  const navClass = classNames('nav', {
    ['nav--active']: showNavigation,
  });

  function handleNavigation() {
    setShowNavigation(!showNavigation);
  }

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [router.asPath]);

  return (
    <>
      <header style={{ backgroundColor: pageColor }} className={headerClass}>
        <Logo loading={loading} invertColors={invertColors} />
        <div
          className="header__nav-container"
          style={{ backgroundColor: pageColor }}
        >
          <nav className={navClass}>
            <Logo
              pageColor={pageColor}
              animateCircle={false}
              invertColors={invertColors}
            />
            <MainNavigation
              theme={theme}
              activeNavItem={activeNavItem}
              pageColor={pageColor}
            />
            <SubNavigation
              show={showNavigation || false}
              activeNavItem={activeNavItem}
              setShowNavigation={setShowNavigation}
            />
          </nav>
          <button
            name="burger-menu"
            className={burgerClass}
            onClick={() => handleNavigation()}
          />
        </div>
      </header>
      <MusicPlayer
        // invertColors inverts the colors and makes the player
        // have a white border & white bars on all pages with a black background
        // whereas isNavOpen handles a class that helps with inverting
        // the color when the nav opens on mobile and removes styling
        // on desktop
        invertColors={invertColors}
        isNavOpen={showNavigation}
      />
      <AccessibilityButton
        // invertColors inverts the colors and make the button
        // have a white border on all pages with a black background
        // whereas isNavOpen handles a class that helps with inverting
        // the color when the nav opens on mobile and removes styling
        // on desktop
        invertColors={invertColors}
        isNavOpen={showNavigation}
      />
    </>
  );
};
